<template>
  <div>
    <ValidationObserver v-slot="context">
      <vx-card class="mb-base">
        <form 
          autocomplete="off" 
          @submit.prevent="submit(context)">
          <!-- Email -->
          <FormLayout>
            <FormLabel>
              <span>อีเมล์ (Email) *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Email" 
                rules="required">
                <vs-input
                  v-model="formData.email"
                  class="w-full"
                  name="title"
                />
                <span 
                  v-if="errors.length > 0">{{ errors[0] }}
                  class="text-danger text-sm" 
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <!-- Display name / Display Name -->
          <FormLayout>
            <FormLabel>
              <span>ชื่อผู้ใช้งาน (Display name) *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="DisplayName" 
                rules="required">
                <vs-input
                  v-model="formData.display_name"
                  class="w-full"
                  name="title"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <!-- Password -->
          <FormLayout>
            <FormLabel>
              <span>รหัสผ่าน (Password) *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Password" 
                rules="required">
                <vs-input
                  v-model="formData.password"
                  class="w-full"
                  name="title"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <!-- Password Confirmation-->
          <FormLayout>
            <FormLabel>
              <span>พิมพ์รหัสผ่านอีกครั้ง (Re-Type Password)  *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Password" 
                rules="required">
                <vs-input
                  v-model="formData.passwordConfirmation"
                  class="w-full"
                  name="title"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <!-- Role -->
          <FormLayout>
            <FormLabel>
              <span class="required">ระบุตำแหน่ง (Role) *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="role" 
                rules="required">
                <vs-select
                  v-model="formData.role"
                  name="role"
                  class="selectExample"
                  icon="arrow_downward"
                  autocomplete
                >
                  <vs-select-item
                    v-for="(item, index) in accountRoles"
                    :value="item.role"
                    :text="item.role"
                    :key="index"
                  />
                </vs-select>
                <span 
                  v-show="errors.length > 0" 
                  class="text-danger text-sm"
                >กรุณาระบุตำแหน่ง</span
                >
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <!-- Group -->
          <FormLayout>
            <FormLabel>
              <span class="required">ระบุกลุ่ม (Group)</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="project-user" 
                rules="required">
                <vs-select
                  v-model="formData.group_id"
                  name="project-user"
                  class="selectExample"
                  icon="arrow_downward"
                  autocomplete
                >
                  <vs-select-item
                    v-for="(item, index) in userGroups"
                    :value="item.id"
                    :text="item.group_name"
                    :key="index"
                  />
                </vs-select>
                <span 
                  v-show="errors.length > 0" 
                  class="text-danger text-sm"
                >กรุณาระบุกลุ่ม</span
                >
              </ValidationProvider>
            </FormContent>
          </FormLayout>
        </form>
      </vx-card>
      <vx-card>
        <div class="flex w-full justify-center">
          <vs-button 
            class="mr-2" 
            @click="submit(context)">ยืนยันการสร้างผู้ใช้งาน</vs-button>
            <!-- <vs-button color="warning" type="border" @click="context.reset"
            >Reset</vs-button
          > -->
        </div>
      </vx-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import useNotify from '@/use/useNotify'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    onCreateSuccess: Function,
    // eslint-disable-next-line vue/require-default-prop
    editHouseData: {
      type: Object,
    },
  },
  setup(props, ctx) {
    const {
      root: { $store, $router, $vs },
    } = ctx

    // const { addItem } = useCrud(ctx, 'houseFunctionFeature', {
    //   fetchOnMount: false,
    //   onModifyItemSuccess: props.onCreateSuccess,
    // })

    onMounted(() => {
      $store.dispatch('users/fetchUserGroup')
      $store.dispatch('users/fetchAccountRole')
    })


    const userGroups = computed(() => $store.state.users.groups)
    const accountRoles = computed(() => $store.state.users.roles)

    const formData = reactive({
      email: null,
      display_name: null,
      password: null,
      passwordConfirmation: null,
      role: null,
      group_id: null
    })

    const { success: notifySuccess, error: notifyError } = useNotify(ctx)

    const submit = ({ validate }) => {
        validate().then((result) => {
          if (!result) {
            return null
          }

          $vs.loading()
          $store
          .dispatch(`users/${formData.uid ? 'updateItem' : 'registerUser'}`, formData)
          .then(() => {

            // if no routePrefix params, it means update form is on the same page so we have to refresh data after update successfully
            $vs.loading.close()

            $router.push({
              name: 'user-list',
            })

            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            notifyError({ text: JSON.stringify(error) })
          })
        })
    }

    return {
      submit,
      userGroups,
      accountRoles,
      formData,
    }
  },
}
</script>
