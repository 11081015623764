var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('vx-card',{staticClass:"mb-base"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit(context)}}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("อีเมล์ (Email) *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),(errors.length > 0)?_c('span',[_vm._v(_vm._s(errors[0])+"\n                class=\"text-danger text-sm\" \n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อผู้ใช้งาน (Display name) *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"DisplayName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title"},model:{value:(_vm.formData.display_name),callback:function ($$v) {_vm.$set(_vm.formData, "display_name", $$v)},expression:"formData.display_name"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("รหัสผ่าน (Password) *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("พิมพ์รหัสผ่านอีกครั้ง (Re-Type Password)  *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title"},model:{value:(_vm.formData.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formData, "passwordConfirmation", $$v)},expression:"formData.passwordConfirmation"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("ระบุตำแหน่ง (Role) *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"selectExample",attrs:{"name":"role","icon":"arrow_downward","autocomplete":""},model:{value:(_vm.formData.role),callback:function ($$v) {_vm.$set(_vm.formData, "role", $$v)},expression:"formData.role"}},_vm._l((_vm.accountRoles),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.role,"text":item.role}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุตำแหน่ง")])]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',{staticClass:"required"},[_vm._v("ระบุกลุ่ม (Group)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"project-user","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"selectExample",attrs:{"name":"project-user","icon":"arrow_downward","autocomplete":""},model:{value:(_vm.formData.group_id),callback:function ($$v) {_vm.$set(_vm.formData, "group_id", $$v)},expression:"formData.group_id"}},_vm._l((_vm.userGroups),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.group_name}})}),1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],staticClass:"text-danger text-sm"},[_vm._v("กรุณาระบุกลุ่ม")])]}}],null,true)})],1)],1)],1)]),_c('vx-card',[_c('div',{staticClass:"flex w-full justify-center"},[_c('vs-button',{staticClass:"mr-2",on:{"click":function($event){return _vm.submit(context)}}},[_vm._v("ยืนยันการสร้างผู้ใช้งาน")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }